import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getAppealNumberForRestParam} from "../pages/DefaultPages/common/restCalls";
import {selectUserIsAppellant, selectUserName} from "./app";
import {APPEAL_STATUS_CHANGE_EVENT, AppealStatus} from "../pages/DefaultPages/common/constants";
import {getCurrentUserAndGroups, getJWTToken} from "../pages/DefaultPages/common/UserUtils";
import dayjs from "dayjs";
import {buildContactsParameter} from "../pages/DefaultPages/common/ContactUtils";

export const RestStatus = {
  LOADING: 'loading',
  LOADED: 'loaded',
  LOAD_FAILED: 'loadFailed',
  SAVING: 'saving',
  SAVED: 'saved',
  SAVE_FAILED: 'saveFailed'
};

export const fetchAppeal = createAsyncThunk(
  'appealDetail/fetchAppeal',
  async ({appealNumber, county},{getState, dispatch, requestId}) => {
    const response = await axios
      .get(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_STAGE +
        "/getappealbyid",
        {
          params: {
            _APPEAL_NUMBER: appealNumber,
            dbid: county.key,
            isTen: appealNumber.includes("10@") ? "true" : "false"
          }
        }
      );
    const state = getState();
    const userIsAppellant = selectUserIsAppellant(state);
    const username = selectUserName(state);
    dispatch(fetchComparables(appealNumber));
    dispatch(fetchEvidence(appealNumber));
    dispatch(fetchEvents({appealNumber, source:requestId}));
    if (response.data?.appeal && response.data.appeal.APPEAL.crossAppeal.otherAppeal) {
      dispatch(fetchCrossComparables(response.data.appeal.APPEAL.crossAppeal.otherAppeal));
      dispatch(fetchCrossEvidence(response.data.appeal.APPEAL.crossAppeal.otherAppeal));
    }
    return {data: response.data, userIsAppellant, username};
  }
);

export const refreshAppeal = createAsyncThunk(
  'appealDetail/refreshAppeal',
  async ({source}, {getState}) => {
    const state = getState();
    const appealNumber = selectAppealNumber(state);
    const county = selectCounty(state);
    const response = await axios
      .get(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_STAGE +
        "/getappealbyid",
        {
          params: {
            _APPEAL_NUMBER: appealNumber,
            dbid: county.key,
            isTen: appealNumber.includes("20@") ? "false" : "true"
          }
        }
      );
    const userIsAppellant = selectUserIsAppellant(state);
    const username = selectUserName(state);
    return {data: response.data, userIsAppellant, username};
  }
);

export const fetchComparables = createAsyncThunk(
  'appealDetail/fetchComparables',
  async (appealNumber, {getState}) => {
    console.log("get Comparables");
    const state = getState();
    const county = selectCounty(state);

    const response = await axios
    .get(
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_STAGE +
      "/getcomparablebyappeal",
      {
        params: {
          _APPEAL_NUMBER: getAppealNumberForRestParam(appealNumber, county.key),
        }
      }
    );
    return response.data;
  }
);

export const fetchCrossComparables = createAsyncThunk(
  'appealDetail/fetchCrossComparables',
  async (appealNumber, {getState}) => {
    console.log("get Cross Comparables");
    const state = getState();
    const county = selectCounty(state);

    const response = await axios
    .get(
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_STAGE +
      "/getcomparablebyappeal",
      {
        params: {
          _APPEAL_NUMBER: getAppealNumberForRestParam(appealNumber, county.key),
        }
      }
    );
    return response.data;
  }
);

export const addComparable = createAsyncThunk(
  'appealDetail/addComparable',
  async ({blq, address, salePrice, saleDate, isAssessorComp}, {getState, dispatch, requestId}) => {
    console.log("add Comparable");
    const state = getState();
    const county = selectCounty(state);
    const appealNumber = selectAppealNumber(state);

    const response = await axios
      .post(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_STAGE +
        "/uploadComparable",
        {
          params: {
            _APPEAL_NUMBER: getAppealNumberForRestParam(appealNumber, county.key),
            qual: blq,
            address: address,
            salePrice: salePrice,
            saleDate: saleDate,
            _UUID: selectUserName(state),
            assessor: isAssessorComp
          }
        },
        {
        }
      );

    dispatch(addEvent({eventText: "Comparable Added", source: requestId}));
    if (!isAssessorComp && selectAppealStatus(state) !== AppealStatus.WORK_IN_PROGRESS) {
      dispatch(updateAppealStatus({appealStatus: AppealStatus.WORK_IN_PROGRESS}));
    }
    dispatch(fetchComparables(appealNumber));
    return response.data;
  }
);

export const deleteComparable = createAsyncThunk(
  'appealDetail/deleteComparable',
  async (comp, {dispatch, requestId}) => {
    console.log("delete Comparable");

    const response = await axios
    .delete(
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_STAGE +
      "/deleteComparable",
      {
        params: {
          _APPEAL_NUMBER: comp._APPEAL_NUMBER.trim(),
          _CID: comp._CID.trim()
        }
      }
    );
    dispatch(addEvent({eventText: "Comparable Deleted", source: requestId}));
    dispatch(fetchComparables(comp._APPEAL_NUMBER.substring(2)));
    return response.data;
  }
);

export const addEvent = createAsyncThunk(
  'appealDetail/addEvent',
  async ({eventText, source}, {getState, dispatch, requestId}) => {
    //console.log("Saving notes:" + notes);
    const state = getState();
    const appealNumber = selectAppealNumber(state);
    const county = selectCounty(state);
    const response = await axios
      .post(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_STAGE +
        "/addevent",
        {
          params: {
            _APPEAL_NUMBER: getAppealNumberForRestParam(appealNumber, county.key),
            eventText: eventText,
            time: dayjs().format(),
            _UUID: selectUserName(state),
          }
        },
        {
        }
      );
    dispatch(fetchEvents({appealNumber, source: source || requestId}));
    return response.data;
  }
);

export const fetchEvents = createAsyncThunk(
  'appealDetail/fetchEvents',
  async ({appealNumber, source}, {getState}) => {
    console.log("get Events");
    const state = getState();
    const county = selectCounty(state);
    const userIsAppellant = selectUserIsAppellant(state);
    const username = selectUserName(state);

    const response = await axios
      .get(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_STAGE +
        "/geteventsbyappeal",
        {
          params: {
            _APPEAL_NUMBER: getAppealNumberForRestParam(appealNumber, county.key),
          }
        }
      );
    return {response: response.data, userIsAppellant, username};
  }
);

export const fetchEvidence = createAsyncThunk(
  'appealDetail/fetchEvidence',
  async (appealNumber, {getState}) => {
    console.log("get Evidence");
    const state = getState();
    const county = selectCounty(state);

    const response = await axios
    .get(
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_STAGE +
      "/getevidencebyappeal",
      {
        params: {
          _APPEAL_NUMBER: getAppealNumberForRestParam(appealNumber, county.key),
        }
      }
    );
    return response.data;
  }
);

export const fetchCrossEvidence = createAsyncThunk(
  'appealDetail/fetchCrossEvidence',
  async (appealNumber, {getState}) => {
    console.log("get Cross Evidence");
    const state = getState();
    const county = selectCounty(state);

    const response = await axios
    .get(
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_STAGE +
      "/getevidencebyappeal",
      {
        params: {
          _APPEAL_NUMBER: getAppealNumberForRestParam(appealNumber, county.key),
        }
      }
    );
    return response.data;
  }
);

export const updateAppealStatus = createAsyncThunk(
  'appealDetail/updateAppealStatus',
  async ({appealStatus, notify}, {getState, dispatch, requestId}) => {
    //console.log("Saving notes:" + notes);
    const state = getState();
    const databaseId = selectDatabaseId(state);
    const appealNumber = selectAppealNumber(state);
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/updateAppealStatus`,
        {
          params: {
            _DATABASE_ID: databaseId,
            _APPEAL_NUMBER: appealNumber.trim(),
            blq:selectPropertyBlq(state),
            contacts: buildContactsParameter(
              selectContact(state),
              selectAdditionalContact(state),
              selectThirdPartyContact(state),
              selectAuthorizedAttorneyContact(state)
            ),
            STATUS: appealStatus,
            NOTIFY: notify === undefined ? true : notify,
            COUNTY: selectCounty(state).label || selectCounty(state).name
          }
        },
        {
        }
      );
    dispatch(addEvent({eventText: APPEAL_STATUS_CHANGE_EVENT + appealStatus, source: requestId}));
    dispatch(refreshAppeal({source: requestId}));
    return response.data;
  }
);

export const updateContacts = createAsyncThunk(
  'appealDetail/updateContacts',
  async ({contact, additionalContact, thirdPartyContact, authorizedAttorneyContact}, {getState, dispatch, requestId}) => {
    const state = getState();
    const databaseId = selectDatabaseId(state);
    const appealNumber = selectAppealNumber(state);
    const response = await axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/updatecontactobject`,
      {
        params: {
          _DATABASE_ID: databaseId,
          _APPEAL_NUMBER: appealNumber,
          contactObject: contact,
          additionalContactObj: additionalContact,
          thirdPartyObj: thirdPartyContact,
          authorizedAttorneyObj: authorizedAttorneyContact
        }
      },
      {
      }
    )
    dispatch(refreshAppeal({source: requestId}));
  }

);

export const updateValuation = createAsyncThunk(
  'appealDetail/updateValuation',
  async (
    {reasonForAppeal, hearingType, taxCourtPending, awop, paymentMethod, receiptId, requestedTotal, purchasePrice, purchaseDate},
    {getState, dispatch, requestId}
  ) => {
    const state = getState();
    const databaseId = selectDatabaseId(state);
    const appealNumber = selectAppealNumber(state);

    const valuationObj = {
      appealing: selectIsAppealingValuation(state),
      purchaseDate,
      purchasePrice,
      reason: reasonForAppeal,
      taxCourtPending,
      awop,
      requestedTotal
    };

    Object.keys(valuationObj).forEach(
      key =>
        (valuationObj[key] === null || valuationObj[key] === "") &&
        delete valuationObj[key]
    );

    console.log(valuationObj);

    const response = await axios
      .post(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_STAGE +
        "/updatevaluationobject",
        {
          params: {
            _DATABASE_ID: databaseId,
            _APPEAL_NUMBER: appealNumber.trim(),
            valuationObj: valuationObj,
            hearingType,
            receiptId,
            paymentMethod
          }
        },
        {
        }
      );
    dispatch(refreshAppeal({source: requestId}));
    return response.data;
  }
);

export const updateNotes = createAsyncThunk(
  'appealDetail/updateNotes',
  async (notes, {getState, dispatch, requestId}) => {
    //console.log("Saving notes:" + notes);
    const state = getState();
    const databaseId = selectDatabaseId(state);
    const appealNumber = selectAppealNumber(state);
    const response = await axios
      .post(
        process.env.REACT_APP_API_URL +
        "/" +
        process.env.REACT_APP_STAGE +
        "/updatenotes",
        {
          params: {
            _DATABASE_ID: databaseId,
            _APPEAL_NUMBER: appealNumber.trim(),
            notes: notes
          }
        },
        {
        }
      );
    dispatch(refreshAppeal({source:requestId}));
    return response.data;
  }
);

export const addJudgmentToAppeal = createAsyncThunk(
  'appealDetail/addJudgmentToAppeal',
  async ({judgment, sendNotifications}, {getState, dispatch, requestId}) => {
    const state = getState();
    const newJudgment = !selectJudgmentAddedDate(state);
    const params = {
      _DATABASE_ID: selectDatabaseId(state),
      _APPEAL_NUMBER: selectAppealNumber(state),
      judgement: judgment,
      sendNotifications,
      contacts: buildContactsParameter(
        selectContact(state),
        selectAdditionalContact(state),
        selectThirdPartyContact(state),
        selectAuthorizedAttorneyContact(state)
      ),
      county: selectCounty(state).label || selectCounty(state).name,
      blq: selectPropertyBlq(state),
    }
    if (judgment.judgementResult && judgment.judgementMailDate) {
      params.appealStatus = AppealStatus.JUDGMENT_ISSUED;
    }

    const response = await axios
    .post(
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_STAGE +
      "/addjudgementtoappeal",
      {
        params
      },
      {}
    );
    dispatch(addEvent({eventText: newJudgment ? "Judgment Added" : "Judgment Updated", source:requestId }));
    dispatch(refreshAppeal({ source:requestId }));
    return response.data;
  }
);

export const deleteJudgment = createAsyncThunk(
  'appealDetail/deleteJudgment',
  async ({newAppealStatus}, {getState, dispatch, requestId}) => {
    console.log("delete Judgment");
    const state = getState();

    const response = await axios
    .delete(
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_STAGE +
      "/deletejudgement",
      {
        params: {
          _APPEAL_NUMBER: selectAppealNumber(state),
          _DATABASE_ID: selectDatabaseId(state),
          appealStatus: newAppealStatus,
        }
      }
    );
    dispatch(addEvent({eventText: "Judgment Deleted", source: requestId}));
    dispatch(refreshAppeal({ source:requestId }));
    return response.data;
  }
);

const initialState =  {
  ...getCurrentUserAndGroups(),
  contact:{},
  additionalContact:{},
  thirdPartyContact:{},
  numPages: null,
  pageNumber: 1,
  key: "owner",
  previewVisible: false,
  previewImage: "",
  fileList: [],
  appellantFileList: [],
  stipFileList: [],
  paperFileList: [],
  status: "",
  blq: "",
  address: "",
  salePrice: "",
  saleDate: "",
  appellantBlq: "",
  appellantAddress: "",
  appellantSaleDate: "",
  appellantSalePrice: "",
  comparablesData: [],
  eventsData: [],
  assessorComparablesData: [],
  evidenceData: [],
  assessorEvidenceData: [],
  crossAppeal: {},
  stipulation: {},
  judgmentLandAmount: 0,
  judgmentBuildingAmount: 0,
  judgmentAbatementAmount: 0,
  judgmentClassKey: '',
  judgmentClassLabel: '',
  judgmentExplanation: '',
  judgmentPresentSelected: [],

  propertyValuationData: [
    {
      type: "Current",
      land: "",
      improvement: "",
      abatement: "",
      total: ""
    },
    {
      type: "Request",
      land: "",
      improvement: "",
      abatement: "",
      total: ""
    }
  ]
};

const calculateDatabaseIdForAppeal = appeal => {
  const century = Math.floor(new Date().getFullYear() / 100);
  return `${century}${appeal.appealNumber.slice(6,8)}.${appeal.county.key}`;
};

const populateAppealState = (state, appeal) => {
  state.appealRestStatus.status = RestStatus.LOADED;
  state.data = appeal;
  state.notes = appeal.APPEAL.notes;
  state.crossAppeal = appeal.APPEAL.crossAppeal;
  state.databaseId = appeal.APPEAL.database_id || calculateDatabaseIdForAppeal(appeal.APPEAL);
  state.municipality = appeal.APPEAL.municipality.label;
  state.municipalityKey = appeal.APPEAL.municipality.key;
  state.appealNumber = appeal.APPEAL.appealNumber;
  state.propertyAddress = appeal.APPEAL.property.relevantProperty;
  state.propertyBlq = appeal.APPEAL.property.blq;
  state.contact = appeal.APPEAL.contact;
  state.additionalContact = appeal.APPEAL.additionalContact;
  state.thirdPartyContact = appeal.APPEAL.thirdPartyContact;
  state.authorizedAttorneyContact = appeal.APPEAL.authorizedAttorneyContact;
  state.ownerName = appeal.APPEAL.contact.firstName;
  state.ownerAddress = appeal.APPEAL.contact.address;
  state.ownerCityState = appeal.APPEAL.contact.city;
  state.ownerZip = appeal.APPEAL.contact.zipCode;
  state.contactLawyerLicenseNumber = appeal.APPEAL.additionalContact.aCLawyerLicense;
  state.contactName = appeal.APPEAL.additionalContact.firstName;
  state.contactAddress = appeal.APPEAL.additionalContact.address;
  state.contactCityState = appeal.APPEAL.additionalContact.city;
  state.contactZip = appeal.APPEAL.additionalContact.zipCode;
  state.additionalContactCellPhoneNumber = appeal.APPEAL.additionalContact.dayTimePhone;
  state.additionalContactEmail = appeal.APPEAL.additionalContact.emailAddress;
  state.additionalContactByEmail = appeal.APPEAL.additionalContact.contactEmailCheckbox;
  state.additionalContactByPhone = appeal.APPEAL.additionalContact.contactPhoneCheckbox;
  state.reasonForAppeal = appeal.APPEAL.valuation.reason;
  state.landValue = String(appeal.APPEAL.property.currentAssessment.land);
  state.improvementValue = String(
    appeal.APPEAL.property.currentAssessment.buildingImprovement
  );
  state.abatementValue = String(appeal.APPEAL.property.currentAssessment.abatement);
  state.totalValue = String(appeal.APPEAL.property.currentAssessment.total);
  state.requestedTotalValue = appeal.APPEAL.valuation.requestedTotal || "";
  state.appealingValuation = appeal.APPEAL.valuation.appealing;
  state.status = appeal.APPEAL.status;
  state.appellantEmail = appeal.APPEAL.contact.emailAddress;
  state.appellantPhone = appeal.APPEAL.contact.dayTimePhone;
  state.contactByEmail = appeal.APPEAL.contact.mainEmailCheckbox;
  state.contactByPhone = appeal.APPEAL.contact.mainPhoneCheckbox;
  state.propertyLocation = appeal.APPEAL.property.relevantProperty;
  state.propertyCityState = appeal.APPEAL.property.cityState;
  state.propertyZip = appeal.APPEAL.property.zip;
  state.lotSize = appeal.APPEAL.property.lotSize;
  state.abatementDeductionAppeal = appeal.APPEAL.deduction.abatement;
  state.veteransPropertyDeductionAppeal = appeal.APPEAL.deduction.veteranDeduction;
  state.disabledVeteranDeductionAppeal = appeal.APPEAL.deduction.disabledVeteranExemption;
  state.seniorDisabledDeductionAppeal = appeal.APPEAL.deduction.seniorCitizenOrDisabledPerson;
  state.hearingDate = appeal.APPEAL.hearingDate;
  state.submitDate = appeal.APPEAL.submitDate;
  state.hearingDateFinalized = appeal.APPEAL.hearingDateFinalized ? true : false;
  state.propertyClass = appeal.APPEAL.property.classification;
  state.entryMethod = appeal.APPEAL.entryMethod;
  state.hearingType = appeal.APPEAL.hearingType;
  state.taxCourtPending = appeal.APPEAL.valuation.taxCourtPending;
  state.awop = appeal.APPEAL.valuation.awop;
  state.tenAppeal = appeal.APPEAL.tenAppeal;
  state.purchasePrice = appeal.APPEAL.property.purchasePrice
    ? String(appeal.APPEAL.property.purchasePrice)
    : "";
  state.purchaseDate = appeal.APPEAL.property.purchaseDate;
  state.scanFile = appeal.APPEAL.scanFile;
  state.receiptId = appeal.APPEAL.receiptId;
  state.receivedReasonForWithdrawal = appeal.APPEAL.reasonForWithdrawal;
  state.entryPaymentMethod = appeal.APPEAL.entryPaymentMethod;
  state.fees = appeal.APPEAL.fees;
  state.creditCardPayment = appeal.APPEAL.creditCardPayment;
  state.stipExists = appeal.STIPULATION !== null && appeal.STIPULATION !== undefined;
  state.appealingForClassification = appeal.APPEAL.classification.appealing;
  state.uuid = appeal._UUID;
  state.creatorObj = appeal.APPEAL.creatorObj;
  state.solicitorApproved = appeal.APPEAL.creatorObj?.approved;
  state.approverObject = appeal.APPEAL.creatorObj?.approverObject;
  state.filingDescription = appeal.APPEAL.filingDescription;
  state.isAssessorAppeal = appeal.APPEAL.filingDescription.substring(0,1) === "A";
  state.petitioner = (appeal.APPEAL.crossAppeal.isCrossAppeal && appeal.APPEAL.crossAppeal.otherAppeal.substring(13,14) !== "A") ||
    (!appeal.APPEAL.crossAppeal.isCrossAppeal && appeal.APPEAL.filingDescription.substring(0,1) === "A")
    ? appeal.APPEAL.municipality.label : appeal.APPEAL.contact.firstName;
  state.propertyValuationData = [
    {
      type: "Current",
      land: appeal.APPEAL.property.currentAssessment.land,
      improvement: appeal.APPEAL.property.currentAssessment.buildingImprovement,
      abatement: appeal.APPEAL.property.currentAssessment.abatement,
      total: appeal.APPEAL.property.currentAssessment.total
    },
    {
      type: "Request",
      land: "",
      improvement: "",
      abatement: "",
      total: appeal.APPEAL.valuation.requestedTotal
        ? appeal.APPEAL.valuation.requestedTotal
        : ""
    }
  ];


  if (appeal.APPEAL.improvementRecord && Object.entries(appeal.APPEAL.improvementRecord).length > 0) {
    //console.log(appeal.APPEAL.improvementRecord);
    state.requestedAdditionDate = appeal.APPEAL.improvementRecord.requestedAdditionDate;
    state.requestedAdditionMonth = appeal.APPEAL.improvementRecord.requestedAdditionMonth;
    state.requestedAdditionAmount = appeal.APPEAL.improvementRecord.requestedAdditionAmount;
    state.requestedAdditionLand = appeal.APPEAL.improvementRecord.requestedAdditionLand;
    state.requestedAdditionImprovement = appeal.APPEAL.improvementRecord.requestedAdditionImprovement;
    state.requestedAdditionAbatement = appeal.APPEAL.improvementRecord.requestedAdditionAbatement;
    state.additionPropertyClass = appeal.APPEAL.improvementRecord.propertyClass ? appeal.APPEAL.improvementRecord.propertyClass
        : appeal.APPEAL.improvementRecord.originalRecord?.propertyClass ? appeal.APPEAL.improvementRecord.originalRecord?.propertyClass
        : appeal.APPEAL.property.classification;
    state.stipRequestedAdditionDate = appeal.APPEAL.improvementRecord.requestedAdditionDate;
    state.stipRequestedAdditionMonth = appeal.APPEAL.improvementRecord.requestedAdditionMonth;
    state.stipRequestedAdditionAmount = appeal.APPEAL.improvementRecord.requestedAdditionAmount;
    state.stipRequestedAdditionLand = appeal.APPEAL.improvementRecord.requestedAdditionLand;
    state.stipRequestedAdditionImprovement = appeal.APPEAL.improvementRecord.requestedAdditionImprovement;
    state.stipRequestedAdditionAbatement = appeal.APPEAL.improvementRecord.requestedAdditionAbatement;
    state.editRequestedAdditionDate = appeal.APPEAL.improvementRecord.requestedAdditionDate;
    state.editRequestedAdditionMonth = appeal.APPEAL.improvementRecord.requestedAdditionMonth;
    state.editRequestedAdditionLand = appeal.APPEAL.improvementRecord.requestedAdditionLand;
    state.editRequestedAdditionImprovement = appeal.APPEAL.improvementRecord.requestedAdditionImprovement;
    state.editRequestedAdditionAbatement = appeal.APPEAL.improvementRecord.requestedAdditionAbatement;
    state.improvementType = appeal.APPEAL.improvementRecord.originalRecord ? appeal.APPEAL.improvementRecord.originalRecord.type : "";
    state.improvementRecord = appeal.APPEAL.improvementRecord;
  }

  if (appeal.APPEAL.thirdPartyContact) {
    state.thirdPartyName = appeal.APPEAL.thirdPartyContact.firstName;
    state.thirdPartyAddress = appeal.APPEAL.thirdPartyContact.address;
    state.thirdPartyCityState = appeal.APPEAL.thirdPartyContact.city;
    state.thirdPartyZip = appeal.APPEAL.thirdPartyContact.zipCode;
    state.thirdPartyCellPhoneNumber = appeal.APPEAL.thirdPartyContact.dayTimePhone;
    state.thirdPartyEmail = appeal.APPEAL.thirdPartyContact.emailAddress;
    state.contact3ByEmail = appeal.APPEAL.thirdPartyContact.thirdPartyEmailCheckbox;
    state.contact3ByPhone = appeal.APPEAL.thirdPartyContact.thirdPartyPhoneCheckbox;
  }

  if (appeal.STIPULATION) {
    state.stipFileName = appeal.STIPULATION.fileName;
    state.stipFileUrl = appeal.STIPULATION.stipFile;
    state.stipStatus = appeal.STIPULATION.status;
    state.stipTotal = appeal.STIPULATION.stipAmount.total;
    state.receivedStipBasisForSettlement = appeal.STIPULATION.basisForSettlement;
    state.stipLandAmount = appeal.STIPULATION.stipAmount.landAmount;
    state.stipBuildingAmount = appeal.STIPULATION.stipAmount.buildingAmount;
    state.stipAbatementAmount = appeal.STIPULATION.stipAmount.abatementAmount;
    state.stipMonth = appeal.STIPULATION.requestedAdditionMonth;
    state.stipRequestedAdditionMonth = appeal.STIPULATION.requestedAdditionMonth;
    state.stipRequestedAdditionAmount = appeal.STIPULATION.stipAmount.total;
    state.stipRequestedAdditionLand = appeal.STIPULATION.stipAmount.landAmount;
    state.stipRequestedAdditionImprovement = appeal.STIPULATION.stipAmount.buildingAmount;
    state.stipRequestedAdditionAbatement = appeal.STIPULATION.stipAmount.abatementAmount;
    state.stipPropertyClass = appeal.STIPULATION.propertyClass;
  } else {
    state.stipFileName = "";
    state.stipFileUrl = "";
    state.stipStatus = "";
    state.stipTotal = "";
    state.stipBasisForSettlement = "";
    state.receivedStipBasisForSettlement = "";
    state.stipLandAmount = appeal.APPEAL.property.currentAssessment.land;
    //state.stipBuildingAmount =
    //   appeal.APPEAL.property.currentAssessment.buildingImprovement;
    state.stipBuildingAmount = appeal.APPEAL.deduction.veteranDeduction ||
        appeal.APPEAL.deduction.disabledVeteranExemption || appeal.APPEAL.deduction.seniorCitizenOrDisabledPerson
        ? appeal.APPEAL.property.currentAssessment.buildingImprovement : 0;
    state.stipAbatementAmount = appeal.APPEAL.property.currentAssessment.abatement;
    state.stipMonth = appeal.APPEAL.improvementRecord?.requestedAdditionMonth
  }

  if (appeal.JUDGEMENT) {
    state.receivedJudgmentAddedDate = appeal.JUDGEMENT.judgementAddedDate;
    state.receivedJudgmentAmount = appeal.JUDGEMENT.judgementAmount;
    state.receivedJudgmentResult = appeal.JUDGEMENT.judgementResult;
    state.receivedJudgmentResultTwo = appeal.JUDGEMENT.judgementResultTwo;
    state.receivedPresentSelected = appeal.JUDGEMENT.presentSelected;
    state.judgmentPropertyClass = appeal.JUDGEMENT.judgementPropertyClass;
    state.mailDate = appeal.JUDGEMENT.judgementMailDate;
    state.judgmentLandAmount = appeal.JUDGEMENT.judgementAmount ? appeal.JUDGEMENT.judgementAmount.landAmount : '';
    state.judgmentBuildingAmount = appeal.JUDGEMENT.judgementAmount ? appeal.JUDGEMENT.judgementAmount.buildingAmount : '';
    state.judgmentAbatementAmount = appeal.JUDGEMENT.judgementAmount ? appeal.JUDGEMENT.judgementAmount.abatementAmount : '';
    state.judgmentClassKey = appeal.JUDGEMENT.judgementPropertyClass ? appeal.JUDGEMENT.judgementPropertyClass.key : '';
    state.judgmentClassLabel = appeal.JUDGEMENT.judgementPropertyClass ? appeal.JUDGEMENT.judgementPropertyClass.label : '';
    state.judgmentExplanation = appeal.JUDGEMENT.judgementExplanation ? appeal.JUDGEMENT.judgementExplanation : '';
    state.judgmentPresentSelected = appeal.JUDGEMENT.presentSelected ? appeal.JUDGEMENT.presentSelected : [];
  } else {
    state.receivedJudgmentAddedDate = "";
    state.receivedJudgmentAmount = {};
    //state.receivedJudgmentResult = "";
    //state.receivedJudgmentResultTwo = "";
    state.receivedPresentSelected = [];
    //state.judgmentPropertyClass = "";
    state.mailDate = "";
    //state.judgmentLandAmount = "";
    //state.judgmentBuildingAmount = "";
    //state.judgmentAbatementAmount = "";
    state.judgmentClassKey = "";
    state.judgmentClassLabel = "";
    state.judgmentExplanation = "";
    state.judgmentPresentSelected = [];
  }

  if (appeal.APPEAL.classification.appealing && appeal.APPEAL.classification.requestedClass) {
    state.requestedClass = appeal.APPEAL.classification.requestedClass.key,
    state.receivedClassification = appeal.APPEAL.classification.requestedClass.label
  } else {
    state.requestedClass = "",
    state.receivedClassification = ""
  }

  const hearingDateExists = appeal.APPEAL.hearingDate ? true : false;
  let isWithinSevenDaysOfHearing = false;
  if (hearingDateExists) {
    if (appeal.APPEAL.county.key !== '04') {
      isWithinSevenDaysOfHearing = dayjs(appeal.APPEAL.hearingDate).endOf('day').isBefore(dayjs().add(7, 'days'));
    } else {
      isWithinSevenDaysOfHearing = dayjs(appeal.APPEAL.hearingDate).hour(16).minute(0).second(0).millisecond(0).isBefore(dayjs().add(7, 'days'));
    }
  }
  // const isAfterHearing = hearingDateExists ? dayjs(appeal.APPEAL.hearingDate).endOf('day').isAfter(Date.now()) : false;
  const isInEndState = ((appeal.APPEAL.status && (appeal.APPEAL.status.toUpperCase() === AppealStatus.JUDGMENT_ISSUED.toUpperCase())) ||
    appeal.APPEAL.status === AppealStatus.STIPULATED || appeal.APPEAL.status === AppealStatus.WITHDRAWN);

  // console.log(appeal.APPEAL.status);
  // console.log(appeal.APPEAL.hearingDate);
  // console.log(dayjs(appeal.APPEAL.hearingDate).endOf('day').add(7, 'days'));
  // console.log(new Date());
  // console.log(dayjs(appeal.APPEAL.hearingDate).endOf('day').isBefore(dayjs().add(7, 'days')));
  // console.log(isWithinSevenDaysOfHearing);
  // console.log(isInEndState);

  state.inLockOutPeriod = isWithinSevenDaysOfHearing;
  state.inEndState = isInEndState;

};


export const appealDetailSlice = createSlice({
  name: 'appealDetail',
  initialState: initialState,
  reducers: {
    setCounty: (state, action) => {
      state.county = action.payload;
      state.isAssessorCounty = action.payload.key !== '11';
    },
    clearStip: (state) => {
      state.stipulation= {};
    },
    setHearingType: (state, action) => {
      state.hearingType = action.payload;
    },
    setReasonForAppeal: (state, action) => {
      state.reasonForAppeal = action.payload;
    },
    setTaxCourtPending: (state, action) => {
      state.taxCourtPending = action.payload;
    },
    setAWOP: (state, action) => {
      state.awop = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(addEvent.pending, (state, action) => {
        state.eventsRestStatus = {status: RestStatus.SAVING, source:action.meta.arg.source, requestId:action.meta.requestId};
        state.saveEventError = '';
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.eventsRestStatus.status = RestStatus.SAVED;
      })
      .addCase(addEvent.rejected, (state, action) => {
        state.eventsRestStatus.status = RestStatus.SAVE_FAILED;
        state.saveEventError = action.error.message;
      })
      .addCase(fetchAppeal.pending, (state, action) => {
        state.appealRestStatus = {status: RestStatus.LOADING, source:action.meta.arg.source, requestId:action.meta.requestId};
        state.fetchAppealError = '';
      })
      .addCase(fetchAppeal.fulfilled, (state, action) => {
        const {data, userIsAppellant, username} = action.payload;
        if (!data || !data.appeal) {
          state.appealRestStatus.status = RestStatus.LOAD_FAILED;
          state.fetchAppealError = 'Appeal was not found';
        } else {
          if( userIsAppellant ){
            if(data.appeal._UUID !== username) {
              state.appealRestStatus.status = RestStatus.LOAD_FAILED;
              state.fetchAppealError = 'Appeal was not found';
              return;
            }
          }
          populateAppealState(state, data.appeal);
        }
      })
      .addCase(fetchAppeal.rejected, (state, action) => {
        state.appealRestStatus.status = RestStatus.LOAD_FAILED;
        state.fetchAppealError = action.error.message;
      })
      .addCase(refreshAppeal.pending, (state, action) => {
        const restStatus = {status: RestStatus.LOADING, requestId:action.meta.requestId};
        if (action.meta.arg?.source) {
          restStatus.source = action.meta.arg.source;
        }
        state.appealRestStatus = restStatus;
        state.fetchAppealError = '';
      })
      .addCase(refreshAppeal.fulfilled, (state, action) => {
        const {data} = action.payload;
        if (!data || !data.appeal) {
          state.appealRestStatus.status = RestStatus.LOAD_FAILED;
          state.fetchAppealError = 'Appeal was not found';
        } else {
          populateAppealState(state, data.appeal);
        }
      })
      .addCase(refreshAppeal.rejected, (state, action) => {
        state.appealRestStatus.status = RestStatus.LOAD_FAILED;
        state.fetchAppealError = action.error.message;
      })
    .addCase(fetchComparables.pending, (state, action) => {
      state.comparablesRestStatus = RestStatus.LOADING;
      state.comparablesData= [];
      state.assessorComparablesData = [];
    })
    .addCase(fetchComparables.fulfilled, (state, action) => {
      const response = action.payload;
      state.comparablesData = response.comparables.filter(
        obj => obj.assessor !== true
      );
      state.assessorComparablesData = response.comparables.filter(
        obj => obj.assessor === true
      );
      state.comparablesRestStatus = RestStatus.LOADED;
    })
    .addCase(fetchComparables.rejected, (state, action) => {
      state.comparablesRestStatus = RestStatus.LOAD_FAILED;
      state.fetchComparablesError = action.error.message;
    })
    .addCase(fetchCrossComparables.pending, (state, action) => {
      state.crossComparablesData= [];
      state.crossAssessorComparablesData = [];
      state.crossComparablesRestStatus = RestStatus.LOADING;
    })
    .addCase(fetchCrossComparables.fulfilled, (state, action) => {
      const response = action.payload;
      state.crossComparablesData = response.comparables.filter(
        obj => obj.assessor !== true
      );
      state.crossAssessorComparablesData = response.comparables.filter(
        obj => obj.assessor === true
      );
      state.crossComparablesRestStatus = RestStatus.LOADED;
    })
    .addCase(fetchCrossComparables.rejected, (state, action) => {
      state.crossComparablesRestStatus = RestStatus.LOAD_FAILED;
      state.fetchCrossComparablesError = action.error.message;
    })
    .addCase(addComparable.pending, (state, action) => {
      state.comparablesRestStatus = RestStatus.SAVING;
      state.saveComparableError = '';
    })
    .addCase(addComparable.fulfilled, (state, action) => {
      state.comparablesRestStatus = RestStatus.SAVED;
    })
    .addCase(addComparable.rejected, (state, action) => {
      state.comparablesRestStatus = RestStatus.SAVE_FAILED;
      state.saveComparableError = action.error.message;
    })
      .addCase(deleteComparable.pending, (state, action) => {
        state.comparablesRestStatus = RestStatus.SAVING;
        state.saveComparableError = '';
      })
      .addCase(deleteComparable.fulfilled, (state, action) => {
        state.comparablesRestStatus = RestStatus.SAVED;
      })
      .addCase(deleteComparable.rejected, (state, action) => {
        state.comparablesRestStatus = RestStatus.SAVE_FAILED;
        state.saveComparableError = action.error.message;
      })
    .addCase(addJudgmentToAppeal.pending, (state, action) => {
      const { requestId } = action.meta;
      state.judgmentRestStatus = {status: RestStatus.SAVING, requestId};
      state.saveJudgmentError = '';
    })
    .addCase(addJudgmentToAppeal.fulfilled, (state, action) => {
      state.judgmentRestStatus.status = RestStatus.SAVED;
    })
    .addCase(addJudgmentToAppeal.rejected, (state, action) => {
      state.judgmentRestStatus.status = RestStatus.SAVE_FAILED;
      state.saveJudgmentError = action.error.message;
    })
    .addCase(deleteJudgment.pending, (state, action) => {
      const { requestId } = action.meta;
      state.judgmentRestStatus = {status: RestStatus.SAVING, requestId};
      state.judgmentRestError = '';
    })
    .addCase(deleteJudgment.fulfilled, (state, action) => {
      state.judgmentRestStatus.status = RestStatus.SAVED;
    })
    .addCase(deleteJudgment.rejected, (state, action) => {
      state.judgmentRestStatus.status = RestStatus.SAVE_FAILED;
      state.judgmentRestError = action.error.message;
    })
      .addCase(fetchEvents.pending, (state, action) => {
        state.eventsData = [];
        state.eventsRestStatus = {status:RestStatus.LOADING, requestId: action.meta.requestId, source: action.meta.arg?.source };
        state.fetchEventsError = '';
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const {response, userIsAppellant, username} = action.payload;
        console.log("***** getEvents response", response);
        if (userIsAppellant) {
          if (response !== null) {
            state.eventsData = response.events.filter(obj => obj._UUID === username);
          }
        } else {
          if (response !== null) {
            state.eventsData = response.events;
          }
        }
        //Get withdrawn date from event
        const withdrawnEvents = response.events.filter(i => i.eventText === APPEAL_STATUS_CHANGE_EVENT + AppealStatus.WITHDRAWN);
        if (withdrawnEvents.length > 0) {
          state.withdrawnDate = withdrawnEvents[0].time;
        }
        state.eventsRestStatus.status = RestStatus.LOADED;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.eventsRestStatus.status = RestStatus.LOAD_FAILED;
        state.fetchEventsError = action.error.message;
      })
    .addCase(fetchEvidence.pending, (state, action) => {
      state.evidenceData= [];
      state.assessorEvidenceData = [];
      state.evidenceRestStatus = RestStatus.LOADING;
      state.fetchEvidenceError = null;
    })
    .addCase(fetchEvidence.fulfilled, (state, action) => {
      const response = action.payload;
      state.evidenceData = response.evidence.filter(
        obj => obj.assessor !== true
      );
      state.assessorEvidenceData = response.evidence.filter(
        obj => obj.assessor === true
      );
      state.evidenceRestStatus = RestStatus.LOADED;
    })
    .addCase(fetchEvidence.rejected, (state, action) => {
      state.evidenceRestStatus = RestStatus.LOAD_FAILED;
      state.fetchEvidenceError = action.error.message;
    })
    .addCase(fetchCrossEvidence.pending, (state, action) => {
      state.crossEvidenceData= [];
      state.crossAssessorEvidenceData = [];
      state.crossEvidenceRestStatus = RestStatus.LOADED;
      state.fetchCrossEvidenceError = null;
    })
    .addCase(fetchCrossEvidence.fulfilled, (state, action) => {
      const response = action.payload;
      state.crossEvidenceData = response.evidence.filter(
        obj => obj.assessor !== true
      );
      state.crossAssessorEvidenceData = response.evidence.filter(
        obj => obj.assessor === true
      );
      state.crossEvidenceRestStatus = RestStatus.LOADED;
    })
    .addCase(fetchCrossEvidence.rejected, (state, action) => {
      state.crossEvidenceRestStatus = RestStatus.LOAD_FAILED;
      state.fetchCrossEvidenceError = action.error.message;
    })
    .addCase(updateAppealStatus.pending, (state, action) => {
      state.statusRestStatus = RestStatus.SAVING;
      state.saveAppealError = '';
    })
    .addCase(updateAppealStatus.fulfilled, (state, action) => {
      state.statusRestStatus = RestStatus.SAVED;
    })
    .addCase(updateAppealStatus.rejected, (state, action) => {
      state.statusRestStatus = RestStatus.SAVE_FAILED;
      state.saveAppealError = action.error.message;
    })
    .addCase(updateContacts.pending, (state, action) => {
      state.contactsRestStatus = {status: RestStatus.SAVING, requestId:action.meta.requestId};
      state.saveContactsError = '';
    })
    .addCase(updateContacts.fulfilled, (state, action) => {
      state.contactsRestStatus.status = RestStatus.SAVED;
    })
    .addCase(updateContacts.rejected, (state, action) => {
      state.contactsRestStatus.status = RestStatus.SAVE_FAILED;
      state.saveContactsError = action.error.message;
    })
      .addCase(updateNotes.pending, (state, action) => {
        state.notesRestStatus = RestStatus.SAVING;
        state.saveNotesError = '';
      })
      .addCase(updateNotes.fulfilled, (state, action) => {
        state.notesRestStatus = RestStatus.SAVED;
      })
      .addCase(updateNotes.rejected, (state, action) => {
        state.notesRestStatus = RestStatus.SAVE_FAILED;
        state.saveNotesError = action.error.message;
      })
      .addCase(updateValuation.pending, (state, action) => {
        state.valuationRestStatus = RestStatus.SAVING;
        state.saveValuationError = '';
      })
      .addCase(updateValuation.fulfilled, (state, action) => {
        state.valuationRestStatus = RestStatus.SAVED;
      })
      .addCase(updateValuation.rejected, (state, action) => {
        state.valuationRestStatus = RestStatus.SAVE_FAILED;
        state.saveValuationError = action.error.message;
      })
  },
});

export const {clearEvidence, clearStip, setAWOP, setCounty, setHearingType, setReasonForAppeal, setTaxCourtPending} = appealDetailSlice.actions;
export const selectAppealNumber = state => state.appealDetail.appealNumber;
export const selectAppealStatus = state => state.appealDetail.status;
export const selectCounty = state => state.appealDetail.county;
export const selectIsAssessorCounty = state => state.appealDetail.isAssessorCounty;
export const selectDatabaseId = state => state.appealDetail.databaseId;
export const selectEvidence = state => state.appealDetail.evidenceData;
export const selectAssessorEvidence = state => state.appealDetail.assessorEvidenceData;
export const selectCrossEvidence = state => state.appealDetail.crossEvidenceData;
export const selectCrossAssessorEvidence = state => state.appealDetail.crossAssessorEvidenceData;
export const selectInLockoutPeriod = state => state.appealDetail.inLockOutPeriod;
export const selectInEndState = state => state.appealDetail.inEndState;
export const selectNotes = state => state.appealDetail.notes;
export const selectAbatementValue = state =>  state.appealDetail.abatementValue;
export const selectAppellantPhone = state =>  state.appealDetail.appellantPhone;
export const selectAdditionalContactCellPhoneNumber = state => state.appealDetail.additionalContactCellPhoneNumber;
export const selectThirdPartyContactCellPhoneNumber = state => state.appealDetail.thirdPartyCellPhoneNumber;
export const selectAuthorizedAttorneyContactCellPhoneNumber = state => state.appealDetail.authorizedAttorneyContact.dayTimePhone;
export const selectAppellantEmail = state => state.appealDetail.appellantEmail;
export const selectAdditionalContactEmail = state => state.appealDetail.additionalContactEmail;
export const selectThirdPartyContactEmail = state => state.appealDetail.thirdPartyEmail;
export const selectAuthorizedAttorneyContactEmail = state => state.appealDetail.authorizedAttorneyContact.emailAddress;
export const selectContact = state => state.appealDetail.contact;
export const selectAdditionalContact = state => state.appealDetail.additionalContact;
export const selectThirdPartyContact = state => state.appealDetail.thirdPartyContact;
export const selectAuthorizedAttorneyContact = state => state.appealDetail.authorizedAttorneyContact;
export const selectAdditionalContactByEmail = state => state.appealDetail.additionalContactByEmail;
export const selectAdditionalContactByPhone = state => state.appealDetail.additionalContactByPhone;
export const selectContactByEmail = state => state.appealDetail.contactByEmail;
export const selectContactByPhone = state => state.appealDetail.contactByPhone;
export const selectAuthorizedAttorneyContactByEmail = state => state.appealDetail.authorizedAttorneyContact.authorizedAttorneyEmailCheckbox;
export const selectAuthorizedAttorneyContactByPhone = state => state.appealDetail.authorizedAttorneyContact.authorizedAttorneyPhoneCheckbox;
export const selectThirdPartyContactByEmail = state => state.appealDetail.thirdPartyContact.thirdPartyEmailCheckbox;
export const selectThirdPartyContactByPhone = state => state.appealDetail.thirdPartyContact.thirdPartyPhoneCheckbox;
export const selectHearingType = state => state.appealDetail.hearingType;
export const selectIsAppealingValuation = state => state.appealDetail.appealingValuation;
export const selectPurchaseDate = state => state.appealDetail.purchaseDate;
export const selectPurchasePrice = state => state.appealDetail.purchasePrice;
export const selectReasonForAppeal = state => state.appealDetail.reasonForAppeal;
export const selectAWOP = state => state.appealDetail.awop;
export const selectTaxCourtPending = state => state.appealDetail.taxCourtPending;
export const selectPropertyValuationData = state => state.appealDetail.propertyValuationData;
export const selectAppealEnteredByUUID = state => state.appealDetail.uuid;
export const selectJudgmentResult = state => state.appealDetail.receivedJudgmentResult;
export const selectJudgmentResultTwo = state => state.appealDetail.receivedJudgmentResultTwo;
export const selectJudgmentAmount = state => state.appealDetail.receivedJudgmentAmount;
export const selectJudgmentPropertyClass = state => state.appealDetail.judgmentPropertyClass;
export const selectJudgmentExplanation = state => state.appealDetail.judgmentExplanation;
export const selectJudgmentPresentSelected = state => state.appealDetail.judgmentPresentSelected;
export const selectJudgmentAddedDate = state => state.appealDetail.receivedJudgmentAddedDate;
export const selectJudgmentMailDate = state => state.appealDetail.mailDate;
export const selectImprovementRecord = state => state.appealDetail.improvementRecord;
export const selectRequestedAdditionLand = state => state.appealDetail.requestedAdditionLand;
export const selectRequestedAdditionImprovement = state => state.appealDetail.requestedAdditionImprovement;
export const selectRequestedAdditionAbatement = state => state.appealDetail.requestedAdditionAbatement;
export const selectRequestedAdditionAmount = state => state.appealDetail.requestedAdditionAmount;
export const selectRequestedAdditionDate = state => state.appealDetail.requestedAdditionDate;
export const selectRequestedAdditionMonth = state => state.appealDetail.requestedAdditionMonth;
export const selectAdditionPropertyClass = state => state.appealDetail.additionPropertyClass;
export const selectAppealingForClassification = state => state.appealDetail.appealingForClassification;
export const selectAbatementDeductionAppeal = state => state.appealDetail.abatementDeductionAppeal;
export const selectVeteransPropertyDeductionAppeal = state => state.appealDetail.veteransPropertyDeductionAppeal;
export const selectDisabledVeteranDeductionAppeal = state => state.appealDetail.disabledVeteranDeductionAppeal;
export const selectSeniorDisabledDeductionAppeal = state => state.appealDetail.seniorDisabledDeductionAppeal;
export const selectClassification = state => state.appealDetail.classification;
export const selectEntryMethod = state => state.appealDetail.entryMethod;
export const selectHearingDate = state => state.appealDetail.hearingDate;
export const selectHearingDateFinalized = state => state.appealDetail.hearingDateFinalized;
export const selectImprovementType = state => state.appealDetail.improvementType;
export const selectImprovementValue = state => state.appealDetail.improvementValue;
export const selectLandValue = state => state.appealDetail.landValue;
export const selectLotSize = state => state.appealDetail.lotSize;
export const selectMunicipality = state => state.appealDetail.municipality;
export const selectMunicipalityKey = state => state.appealDetail.municipalityKey;
export const selectPropertyAddress = state => state.appealDetail.propertyAddress;
export const selectPropertyBlq = state => state.appealDetail.propertyBlq;
export const selectPropertyLocation = state => state.appealDetail.propertyLocation;
export const selectPropertyZip = state => state.appealDetail.propertyZip;
export const selectReceivedReasonForWithdrawal = state => state.appealDetail.receivedReasonForWithdrawal;
export const selectReceiptId = state => state.appealDetail.receiptId;
export const selectPaperFileList = state => state.appealDetail.paperFileList;
export const selectScanFile = state => state.appealDetail.scanFile;
export const selectTenAppeal = state => state.appealDetail.tenAppeal;
export const selectTotalValue = state => state.appealDetail.totalValue;
export const selectStipExists = state => state.appealDetail.stipExists;
export const selectStipFileName = state => state.appealDetail.stipFileName;
export const selectStipFileUrl = state => state.appealDetail.stipFileUrl;
export const selectStipStatus = state => state.appealDetail.stipStatus;
export const selectStipTotal = state => state.appealDetail.stipTotal;
export const selectReceivedStipBasisForSettlement = state => state.appealDetail.receivedStipBasisForSettlement;
export const selectStipLandAmount = state => state.appealDetail.stipLandAmount;
export const selectStipBuildingAmount = state => state.appealDetail.stipBuildingAmount;
export const selectStipAbatementAmount = state => state.appealDetail.stipAbatementAmount;
export const selectStipMonth = state => state.appealDetail.stipMonth;
export const selectStipRequestedAdditionDate = state => state.appealDetail.stipRequestedAdditionDate;
export const selectStipRequestedAdditionMonth = state => state.appealDetail.stipRequestedAdditionMonth;
export const selectStipRequestedAdditionAmount = state => state.appealDetail.stipRequestedAdditionAmount;
export const selectStipRequestedAdditionLand = state => state.appealDetail.stipRequestedAdditionLand;
export const selectStipRequestedAdditionImprovement = state => state.appealDetail.stipRequestedAdditionImprovement;
export const selectStipRequestedAdditionAbatement = state => state.appealDetail.stipRequestedAdditionAbatement;
export const selectStipPropertyClass = state => state.appealDetail.stipPropertyClass;
export const selectCreatorObj = state => state.appealDetail.creatorObj;
export const selectEventsData = state => state.appealDetail.eventsData;
export const selectFetchAppealError = state => state.appealDetail.fetchAppealError;
export const selectAppealRestStatus = state => state.appealDetail.appealRestStatus;
export const selectEventsRestStatus = state => state.appealDetail.eventsRestStatus;
export const selectJudgmentRestStatus = state => state.appealDetail.judgmentRestStatus;
export const selectEntryPaymentMethod = state => state.appealDetail.entryPaymentMethod;
export const selectSubmitDate = state => state.appealDetail.submitDate;
export const selectPropertyClass = state => state.appealDetail.propertyClass;
export const selectPropertyClassKey = state => state.appealDetail.propertyClassKey;
export const selectOwnerName = state => state.appealDetail.ownerName;
export const selectOwnerAddress = state => state.appealDetail.ownerAddress;
export const selectOwnerCityState = state => state.appealDetail.ownerCityState;
export const selectOwnerZip = state => state.appealDetail.ownerZip;
export const selectContactName = state => state.appealDetail.contactName;
export const selectContactAddress = state => state.appealDetail.contactAddress;
export const selectContactCityState = state => state.appealDetail.contactCityState;
export const selectContactZip = state => state.appealDetail.contactZip;
export const selectRequestedTotalValue = state => state.appealDetail.requestedTotalValue;
export const selectReceivedClassification = state => state.appealDetail.receivedClassification;
export const selectComparablesData = state => state.appealDetail.comparablesData;
export const selectAssessorComparablesData = state => state.appealDetail.assessorComparablesData;
export const selectCrossComparablesData = state => state.appealDetail.crossComparablesData;
export const selectCrossAssessorComparablesData = state => state.appealDetail.crossAssessorComparablesData;
export const selectRequestedClass = state => state.appealDetail.requestedClass;
export const selectSolicitorApproved = state => state.appealDetail.solicitorApproved;
export const selectApproverObject = state => state.appealDetail.approverObject;
export const selectContactLawyerLicenseNumber = state => state.appealDetail.contactLawyerLicenseNumber;
export const selectAppeal = state => state.appealDetail.data;
export const selectAssessorObj = state => state.appealDetail.data?.STIPULATION?.assessorObj;
export const selectWithdrawnDate = state => state.appealDetail.withdrawnDate;
export const selectPetitioner = state => state.appealDetail.petitioner;
export const selectIsAssessorAppeal = state => state.appealDetail.isAssessorAppeal;
export const selectIsCrossAppeal = state => state.appealDetail.crossAppeal.isCrossAppeal;
export const selectIsCrossedAppeal = state => state.appealDetail.crossAppeal.isCrossedAppeal;
export const selectOtherAppealNumber = state => state.appealDetail.crossAppeal.otherAppeal;
export const selectFees = state => state.appealDetail.fees;
export const selectCreditCardPayment = state => state.appealDetail.creditCardPayment;
export const selectContactsRestStatus = state => state.appealDetail.contactsRestStatus;
export const selectSaveContactsError = state => state.appealDetail.saveContactsError;

export default appealDetailSlice.reducer;