
export const buildContactsParameter = (mainContact, additionalContact, thirdPartyContact, authorizedAttorneyContact) => {
  const contactsParameter = [];
  const mainContactParam = buildContactParameter(mainContact, 'mainEmailCheckbox', 'mainPhoneCheckbox');
  if (mainContactParam) {
    contactsParameter.push(mainContactParam);
  }
  const additionalContactParam = buildContactParameter(additionalContact, 'contactEmailCheckbox', 'contactPhoneCheckbox');
  if (additionalContactParam) {
    contactsParameter.push(additionalContactParam);
  }
  const thirdPartyContactParam = buildContactParameter(thirdPartyContact, 'thirdPartyEmailCheckbox', 'thirdPartyPhoneCheckbox');
  if (thirdPartyContactParam) {
    contactsParameter.push(thirdPartyContactParam);
  }
  const authorizedAttorneyContactParam = buildContactParameter(authorizedAttorneyContact, 'authorizedAttorneyEmailCheckbox', 'authorizedAttorneyPhoneCheckbox');
  if (authorizedAttorneyContactParam) {
    contactsParameter.push(authorizedAttorneyContactParam);
  }
  return contactsParameter;
}

const buildContactParameter = (contact, byEmailAttribute, byPhoneAttribute) => {
  if (!contact || (!contact[byEmailAttribute] && !contact[byPhoneAttribute])) {
    return null;
  }
  const param = {};
  if (contact[byEmailAttribute]) {
    param.byEmail = true;
    param.emailAddress = contact.emailAddress;
  }
  if (contact[byPhoneAttribute]) {
    param.byPhone = true;
    param.cellPhone = contact.dayTimePhoneNumber;
  }
  return param;
}