import React from 'react';
import {Button} from "antd";
import './style.scss'

const Greeting = ({user, signOut}) => {
  return (
    <div className="greeting ">
      <span>Hello {user.username}</span>
      <Button
        onClick={signOut}
        size="large"
        type="primary"
      >Sign Out</Button>
    </div>
  );
}

export default Greeting;