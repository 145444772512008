import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {getJWTToken} from "../pages/DefaultPages/common/UserUtils";
import {
  selectUserCounty,
  selectUserIsAppellant,
  selectUserIsAuthorizedAttorney,
  selectUserMunicipalities,
  selectUserName
} from "./app";
import {getActiveAppealYear, getJudgmentSinceDate} from "../pages/DefaultPages/common/CountyUtils";
import {AppealStatus, AppealType} from "../pages/DefaultPages/common/constants";
import pako from "pako";
import {RestStatus} from "./appealDetail";

export const LoadStatus = {
  NOT_LOADED: "not loaded",
  IN_PROGRESS: "in progress",
  SUCCESS: "success",
  FAILED: "failed"
};

const initialState = {
  allAppeals: [],
  workInProgressAppeals: [],
  stipulatedAppeals: [],
  readyForBoardAppeals: [],
  judgmentIssuedAppeals: [],
  withdrawnAppeals: [],
  scheduleSaveCount: 0,
  loadStatus: LoadStatus.NOT_LOADED
};

export const GET_ADDITION_APPEALS = 'ten';
export const GET_VALUE_DEDUCTION_APPEALS = 'twenty';
export const GET_ALL_APPEALS = 'both';

export const fetchAppeals = createAsyncThunk(
  'appealList/fetchAppeals',
  async ({appealType, appealYear, judgmentSince},{getState, dispatch}) => {
    const state = getState();
    const userIsAppellant = selectUserIsAppellant(state);
    const userIsAuthorizedAttorney = selectUserIsAuthorizedAttorney(state);
    const username = selectUserName(state);
    const userCounty = selectUserCounty(state);
    const userMunicipalities = selectUserMunicipalities(state) || [];
    const params = {type: appealType};
    const config = {
      params
    };
    if( !userIsAppellant && userMunicipalities.length > 0) {
      params.appealYear = appealYear || getActiveAppealYear(userCounty, appealType === GET_ADDITION_APPEALS ? AppealType.ADD_OMIT : AppealType.VALUE_CLASS);
      params.townId = userMunicipalities.map(m => m.key).join(',');
      params.countyId = userCounty.key;
      params.judgmentSince = (judgmentSince || getJudgmentSinceDate(userCounty, appealType === GET_ADDITION_APPEALS ? AppealType.ADD_OMIT : AppealType.VALUE_CLASS)).format();
    } else {
      params._UUID = username;
      params.appealYear = appealYear;
    }
    const path = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/search/findallappealsforcounty`;
    const response = await axios
      .get(
        path,
        config
      );
    let appeals = JSON.parse(Buffer(pako.inflate(response.data.data)).toString());
    //console.log("Appeals:", appeals);
    if (userIsAuthorizedAttorney) {
      appeals = appeals.filter(obj => obj.APPEAL.authorizedAttorneyContact?.firstName);
    } else if (appealType !== GET_ALL_APPEALS && (userIsAppellant || userMunicipalities.length === 0) ) {
      appeals = appeals.filter(obj => appealType === GET_ADDITION_APPEALS ? obj.APPEAL.appealNumber.startsWith("10@")
        : !obj.APPEAL.appealNumber.startsWith("10@")) //To handle old appeals that were saved without the 20@
    }
    return appeals;
  }
);

export const scheduleAppeals = createAsyncThunk(
  'appealList/scheduleAppeals',
  async (params) => {
    const path = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/schedule`;
    const data = {params};
    const config = {};
    const response = await axios.post(path, data, config);
    return response.data;
  }
)

export const appealListSlice = createSlice({
  name: 'appealList',
  initialState: initialState,
  reducers: {
    clearAppeals: (state, action) => {
      state.allAppeals = [];
      state.workInProgressAppeals = [];
      state.stipulatedAppeals = [];
      state.readyForBoardAppeals = [];
      state.judgmentIssuedAppeals = [];
      state.withdrawnAppeals = [];
      state.loadStatus = LoadStatus.NOT_LOADED;
      state.loadError = '';
      state.scheduleStatus = '';
      state.scheduleError = '';
      state.scheduleSaveCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchAppeals.pending, (state, action) => {
      state.loadStatus = LoadStatus.IN_PROGRESS;
      state.loadError = '';
    })
    .addCase(fetchAppeals.rejected, (state, action) => {
      state.loadStatus = LoadStatus.FAILED;
      state.loadError = action.error.message;
    })
    .addCase(fetchAppeals.fulfilled, (state, action) => {
      const appeals = action.payload;
      state.allAppeals =  appeals;
      state.workInProgressAppeals = appeals.filter(obj => (obj.APPEAL.status === AppealStatus.WORK_IN_PROGRESS && !obj.APPEAL.valuation.awop));
      state.stipulatedAppeals = appeals.filter(obj => (obj.APPEAL.status === AppealStatus.STIPULATED));
      state.readyForBoardAppeals = appeals.filter(obj => (obj.APPEAL.status === AppealStatus.READY_FOR_BOARD && !obj.APPEAL.valuation.awop));
      state.judgmentIssuedAppeals = appeals.filter(obj => (obj.APPEAL.status && (obj.APPEAL.status.toUpperCase() === AppealStatus.JUDGMENT_ISSUED.toUpperCase())));
      state.withdrawnAppeals =  appeals.filter(obj => (obj.APPEAL.status === AppealStatus.WITHDRAWN));
      state.AWOPAppeals = appeals.filter(obj => obj.APPEAL.valuation.awop && (obj.APPEAL.status === AppealStatus.WORK_IN_PROGRESS || obj.APPEAL.status === AppealStatus.READY_FOR_BOARD));
      state.loadStatus = LoadStatus.SUCCESS;
    })
    .addCase(scheduleAppeals.pending, (state, action) => {
      state.scheduleStatus = RestStatus.SAVING;
      state.scheduleError = '';
    })
    .addCase(scheduleAppeals.rejected, (state, action) => {
      state.scheduleStatus = RestStatus.SAVE_FAILED;
      state.scheduleError = action.error.message;
    })
    .addCase(scheduleAppeals.fulfilled, (state, action) => {
      state.scheduleStatus = RestStatus.SAVED;
      state.scheduleSaveCount = state.scheduleSaveCount + 1;
    })
  },
});

export const {clearAppeals} = appealListSlice.actions;

export const selectAllAppeals = state => state.appealList.allAppeals;
export const selectWorkInProgressAppeals = state => state.appealList.workInProgressAppeals;
export const selectStipulatedAppeals = state => state.appealList.stipulatedAppeals;
export const selectReadyForBoardAppeals = state => state.appealList.readyForBoardAppeals;
export const selectJudgmentIssuedAppeals = state => state.appealList.judgmentIssuedAppeals;
export const selectWithdrawnAppeals = state => state.appealList.withdrawnAppeals;
export const selectAWOPAppeals = state => state.appealList.AWOPAppeals;
export const selectAppealListLoadStatus = state => state.appealList.loadStatus;
export const selectAppealListLoadError = state => state.appealList.loadError;
export const selectScheduleAppealsSaveStatus = state => state.appealList.scheduleStatus;
export const selectScheduleAppealsSaveError = state => state.appealList.scheduleError;
export const selectScheduleAppealsSaveCount = state => state.appealList.scheduleSaveCount;

export default appealListSlice.reducer;