import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { BrowserRouter } from 'react-router-dom';
import 'es6-promise/auto';

import registerServiceWorker, {unregister} from './registerServiceWorker';

import reducer from './ducks';

import 'bootstrap/dist/css/bootstrap.min.css' // bootstrap styles
import {setForceRefresh} from "./ducks/app";
import UserRoutes from "./routes/routes";

if( process.env.REACT_APP_STAGE === "PROD" ){
  require('./resources/AntStyles/AntDesign/antd.cleanui.scss')
  require('./resources/CleanStyles/Core/core.cleanui.scss')
  require('./resources/CleanStyles/Vendors/vendors.cleanui.scss')
} else{
  require('./resources/AntStyles/AntDesign/antd.cleanui.scss')
  require('./resources/CleanStyles/Core/devcore.cleanui.scss')
  require('./resources/CleanStyles/Vendors/vendors.cleanui.scss')
}
// const isLogger = false;
// if (isLogger && process.env.NODE_ENV === 'development') {
//   const { logger } = require('redux-logger');
//   middlewares.push(logger);
// }
const store = configureStore({reducer});

const staleCallback = () => {
  store.dispatch(setForceRefresh());
}

//registerServiceWorker(staleCallback);
unregister();
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <UserRoutes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

export default history
